import React, { useEffect, useState } from 'react';
import styled, { keyframes, css } from 'styled-components';
import LoadingVideo from '../../Assets/Main/LoadingBar.mp4';
import SciFiVideo from '../../Assets/Main/SciFiSetting.mp4';
import SoundVideo from '../../Assets/Main/SoundEffect.webm';
import ActivatingSystem from '../../Assets/Main/activatingsystems.png';
import PressToLaunch from '../../Assets/Main/presstolaunch.png';
import Content0BG from '../../Assets/Main/Body/container_0.png';
import SylvanaImg from '../../Assets/Main/Body/sylvana.png';
import LogoImage from '../../Assets/logo_.png';

export default function MainPage() {
    const [loading, setLoading] = useState(true);
    const [showPage, setShowPage] = useState(false);

    function ConsoleEffect() {
        const [logLines, setLogLines] = useState([
            "Welcome commandant, setting package...",
            "Downloading all dependenciesn needed by the system...",
            "Resolving errors, and conecting to database in mandaloria...",
            "Artificial Intelligence support system online, checking...",
            "Installing components v5.3.0",
            "Setting weapons and energy field",
            "Building project...",
            "Installation complete.",
            "WARNING in ./node_modules/@mediapipe/tasks-vision/vision_bundle.mjs",
            "Module Warning (from ./node_modules/source-map-loader/dist/cjs.js):",
            "Failed to parse source map from 'D:\landing-page\node_modules\@mediapipe\tasks-vision\vision_bundle_mjs.js.map' file: Error: ENOENT: no such file or directory, open 'D:\landing-page\node_modules\@mediapipe\tasks-vision\vision_bundle_mjs.js.map",
        ]);
    
        // Simulación para añadir líneas de texto de manera continua, como una consola de terminal.
        useEffect(() => {
            const interval = setInterval(() => {
                setLogLines((prevLines) => [
                    ...prevLines.slice(-70), 
                    "Loading completed................... "
                ]);
            }, 1000); 
    
            return () => clearInterval(interval);
        }, []);
    
        return (
            <ConsoleContainer loading={loading} showPage={showPage}>
                <ScrollingText>
                    {logLines.map((line, index) => (
                        <ConsoleLine key={index}>{line}</ConsoleLine>
                    ))}
                </ScrollingText>
            </ConsoleContainer>
        );
    }
        
    return (
        <MainContainer>
            <Canvas showPage = {showPage}>
                <SettingAnimation loading={loading}>
                    <SettingSystemImage src={ActivatingSystem} alt="Actv" />
                </SettingAnimation>
                <LoadingAnimation loading={loading}>
                    <video src={LoadingVideo} 
                    autoPlay 
                    muted 
                    playsInline 
                    onEnded={() => setLoading(false)}
                    />
                </LoadingAnimation>
                <SoundAnimation>
                    <video src={SoundVideo} autoPlay loop muted playsInline />
                </SoundAnimation>
                <SciFiAnimation>
                    <video src={SciFiVideo} autoPlay loop muted playsInline />
                </SciFiAnimation>
                <ConsoleEffect showPage={showPage}/>
                <PressToStart loading={loading} showPage={showPage} onClick={() => {setShowPage(true)}}>
                    <img src={PressToLaunch} alt="Press" />
                </PressToStart>

                {/* Bottom Corners */}
                <div className="corner-lower-left" />
                <div className="corner-lower-right" />
            </Canvas>
            <Body>
                <Content_0>
                  <Sylvana src={SylvanaImg} alt="Sylvana" />
                  <Logo src={LogoImage} alt="Primaris" />
                  
                </Content_0>
                <Content_1 />
                <Content_2 />
                <Footer>
                    <p>B U I L D I N G S I T E</p>
                </Footer>
            </Body>
        </MainContainer>
    );
}

// Animations (keyframes) *********************

const fadeIn = keyframes `
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
`;

const flickerIn = keyframes`
    0% {
      opacity: 0;
    }
    10% {
      opacity: 0;
    }
    10.1% {
      opacity: 1;
    }
    10.2% {
      opacity: 0;
    }
    20% {
      opacity: 0;
    }
    20.1% {
      opacity: 1;
    }
    20.6% {
      opacity: 0;
    }
    30% {
      opacity: 0;
    }
    30.1% {
      opacity: 1;
    }
    30.5% {
      opacity: 1;
    }
    30.6% {
      opacity: 0;
    }
    45% {
      opacity: 0;
    }
    45.1% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    55% {
      opacity: 1;
    }
    55.1% {
      opacity: 0;
    }
    57% {
      opacity: 0;
    }
    57.1% {
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    60.1% {
      opacity: 0;
    }
    65% {
      opacity: 0;
    }
    65.1% {
      opacity: 1;
    }
    75% {
      opacity: 1;
    }
    75.1% {
      opacity: 0;
    }
    77% {
      opacity: 0;
    }
    77.1% {
      opacity: 1;
    }
    85% {
      opacity: 1;
    }
    85.1% {
      opacity: 0;
    }
    86% {
      opacity: 0;
    }
    86.1% {
      opacity: 1;
    }
    100% {
      opacity: 1;
    }
  }
`;

const blink = keyframes`
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.2;
    }
    100% {
      opacity: 1;
    }
`;

const scroll = keyframes`
    0% { transform: translateY(0); }
    100% { transform: translateY(-100%); }
`;

const fadeOut = keyframes`
  from { opacity: 1; }
  to { opacity: 0; }
`;

const flickerOut = keyframes`
    0% {
      opacity: 1;
    }
    13.9% {
      opacity: 1;
    }
    14% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    14.9% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    15% {
      opacity: 1;
    }
    22.9% {
      opacity: 1;
    }
    23% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    24.9% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    25% {
      opacity: 1;
    }
    34.9% {
      opacity: 1;
    }
    35% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    39.9% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    40% {
      opacity: 1;
    }
    42.9% {
      opacity: 1;
    }
    43% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    44.9% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    45% {
      opacity: 1;
    }
    50% {
      opacity: 1;
    }
    54.9% {
      opacity: 1;
    }
    55% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    69.4% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    69.5% {
      opacity: 1;
    }
    69.9% {
      opacity: 1;
    }
    70% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    79.4% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    79.9% {
      opacity: 1;
    }
    80% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    89.8% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    89.9% {
      opacity: 1;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    90% {
      opacity: 0;
      -webkit-box-shadow: none;
              box-shadow: none;
    }
    100% {
      opacity: 0;
    }
`;

//Main , Canvas **************************

const MainContainer = styled.div`
    background-color: #000000;
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    overflow: hidden; 
`;

const Canvas = styled.div`
    position: fixed;
    top: 10px;
    left: 10px;
    right: 10px;
    bottom: 10px;
    background-color: ${({showPage}) => showPage ?
    'transparent' :
    '#000000'
    };
    transition: background-color 0.5s ease;
    padding: 20px;
    text-align: center;
    
    box-shadow: inset 0 0 2000px rgba(0,0,0,0.5);
    pointer-events: ${({showPage}) => showPage ?
    'none' :
    'auto' 
    };
    z-index: 2;

    /* Corners */
    &::before, &::after, & > .corner-lower-left, & > .corner-lower-right {
        content: "";
        animation: ${flickerIn} 2s ease-in-out;
        position: absolute;
        width: 20px; 
        height: 20px;
        border: 1px solid #4dfefe;
    }

    /* Up-left */
    &::before {
        top: 0;
        left: 0;
        border-right: none;
        border-bottom: none;
    }

    /* Up-Rught */
    &::after {
        top: 0;
        right: 0;
        border-left: none;
        border-bottom: none;
    }

    /* Bottom-Left */
    & > .corner-lower-left {
        bottom: 0;
        left: 0;
        border-top: none;
        border-right: none;
    }

    /* Bottom-Right */
    & > .corner-lower-right {
        bottom: 0;
        right: 0;
        border-top: none;
        border-left: none;
    }
`;

const LoadingAnimation = styled.div`
    position: absolute;
    animation: ${({ loading }) => loading 
        ? css`${flickerIn} 1s ease-in-out`
        : css`${flickerOut} 1s forwards`
    };
    top: 50%;
    left: 50%;
    /*border: 1px solid #4dfefe;*/
    transform: translate(-50%, -50%);
    width: 200px; 
    height: 200px; 
    z-index: 3;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }; 
    }
`;

const SettingAnimation = styled.div`
    position: absolute;
    animation: ${({ loading }) => loading 
        ? css`${flickerIn} 1s ease-in-out`
        : css`${fadeOut} 1s forwards`
    };
    top: 35%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 200px; 
    height: 80px; 
    z-index: 4;
`;

const SettingSystemImage = styled.img`
  background-color: transparent;
  animation: ${blink} 1.5s ease-in-out infinite;
  width: 100%;
  max-width: 1000px;
  height: auto;
`;

const SoundAnimation = styled.div`
    position: absolute;
    animation: ${fadeIn} 5s ease-in-out;
    top: 5%;
    right: 0%;
    border: 1px solid #4dfefe;
    transform: translate(-50%, -50%);
    width: 100px; 
    height: 50px; 
    z-index: 3;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover; 
    }
`;

const SciFiAnimation = styled.div`
    position: absolute;
    animation: ${flickerIn} 1s ease-in-out;
    bottom: 0%;
    right: 0%;
    /*border: 1px solid #4dfefe;*/
    transform: translate(-50%, -50%);
    width: 100px; 
    height: 100px; 
    z-index: 4;
    video {
        width: 100%;
        height: 100%;
        object-fit: cover; 
    }
`;

const PressToStart = styled.div`
  position: absolute;
  animation: ${({ loading, showPage }) => !loading && !showPage 
      ? css`${flickerIn} 1s ease-in-out`
      : css`${fadeOut} 1s forwards`
  };
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  z-index: 4;
  display: ${({ loading, showPage }) => !loading && !showPage 
      ? 'flex'
      : 'none'
  };
  cursor: pointer;
  align-items: center;
  justify-content: center;
  img {
      animation: ${blink} 2s ease-in-out infinite;
      width: 200px; 
      height: 100px; 
      object-fit: cover;
  }
`;

// Body and Main Page Content***********************

const Body = styled.div`
    position: relative;
    width: calc(100% - 20px);
    padding: 0 10px;
    flex: 1;
    flex-direction: column;
    overflow-y: auto;
    clip-path: inset(10px 10px 10px 10px);
    overflow-y: scroll;
    scrollbar-width: none;
    &::-webkit-scrollbar {
        display: none;
    }
`;

const Content_0 = styled.div`
  background-color: #000000;
  background-image: url(${Content0BG});
  background-size: cover; /* Ajusta para que la imagen cubra todo el fondo */
  background-position: center; /* Centra la imagen en el contenedor */
  width: 100%;
  height: 700px;
  text-align: center;
  overflow: hidden;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    z-index: 7;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 300px;
    background: linear-gradient(to top, rgba(0,0,0,1), rgba(0,0,0,0.00005)); /* Cambia los colores como prefieras */
  }
`;

const Sylvana = styled.img`
  position: absolute;
  z-index: 6;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 450px;
`;

const Logo = styled.img`
  position: absolute;
  z-index: 8;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 1000px;
`;

const Content_1 = styled.div`
    background-color: #000000;
    width: 100%;
    height: 500px;
    text-align: center;
    color: red;
`;

const Content_2 = styled.div`
    background-color: #000000; /* #0c0d10*/
    width: 100%;
    height: 500px;
    text-align: center;
    color: red;
`;

const Footer = styled.footer`
    background-color: #000000;
    height: 500px;
    text-align: center;
    color: #ffffff;
`;

// Console Effect ******************************

const ConsoleContainer = styled.div`
    width: 200px;
    height: 150px;
    animation: ${({ loading }) => loading 
        ? css`${fadeIn} 1.5s ease-in-out`
        : css`${flickerOut} 2s forwards`
    };
    display: ${({ showPage }) => !showPage 
      ? 'flex'
      : 'none'
    };
    background-color: transparent;
    color: #4dfefe;
    font-family: monospace;
    font-size: 8px;
    overflow: hidden;
    border: 1px solid #4dfefe;
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 4;
`;

const ScrollingText = styled.div`
    display: flex;
    flex-direction: column;
    animation: ${scroll} 0.2s linear infinite; /* Ajusta el tiempo de desplazamiento */
    height: 100%;
    padding-left: 5px; /* Agrega un pequeño espacio para simular margen de consola */
`;

const ConsoleLine = styled.div`
    margin-bottom: 1px;
    white-space: pre-wrap;
    text-align: left; /* Asegura que el texto esté alineado a la izquierda */
`;