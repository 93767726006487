import React from 'react';
import LoadingSVG from '../Assets/LoadingEclipse.svg';

const LoadingScreen = () => (
  <div style={styles.loadingContainer}>
    <img src={LoadingSVG} alt="Loading..." style={styles.spinner} />
  </div>
);

const styles = {
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#0c0d10', // Fondo oscuro como en tu diseño
  },
  spinner: {
    width: '100px', // Tamaño ajustable
    height: '100px',
  },
};

export default LoadingScreen;
