import React from "react";
import PrimarisWebsite from "./Components/Landing/Landing";

function App() {
  return (
    <PrimarisWebsite /> 
  );
}

export default App;
